<style lang="scss">
.agb {
  .margin-tb-s {
    @media (max-width: $width-lg) {
      margin-top: 20px !important;
      margin-bottom: 20px !important;
    }
  }
  h2 {
    border-left: 0;
    font-family: 'KleineSans-Medium';
    padding: 15px 0;
    margin-top: 30px;
  }
  h3 {
    font-size: 140%;
    font-family: 'KleineSans-Medium';
    margin-top: 15px;
  }
}
</style>

<template>
  <div class="agb content container">
    <h1>Zahlungs- &amp; Geschäftsbedingungen</h1>
    <p>ALLGEMEINE GESCHÄFTSBEDINGUNGEN DER KLEINE ZEITUNG GMBH &amp; CO KG FÜR WERBEAUFTRÄGE</p>
    <small>Stand: 01. Jänner 2022</small>

    <div class="row justify-content-center">
      <div class="col-lg-12 align-self-center">
        <h2>1. ANWENDUNGSBEREICH UND GELTUNG</h2>
        <h3>1.1. Anwendungsbereich</h3>
        <p>
          Diese Allgemeinen Geschäftsbedingungen (<strong>„AGB“</strong>) sind auf alle Geschäfte der Kleine Zeitung GmbH &amp; Co KG (nachfolgend auch kurz <strong>„Auftragnehmer“</strong>) mit Kunden (nachfolgend kurz der/die <strong>„Auftraggeber“</strong>) über Anzeigenschaltungen, Werbebeilagen und sonstige Formen der Werbung (nachfolgend zusammenfassend kurz
          <strong>„Werbeaufträge“</strong> bzw. <strong>„Werbeauftrag“</strong> oder nur <strong>„Aufträge“</strong> bzw. „Auftrag“) anzuwenden. Gegenläufige Allgemeine Geschäftsbedingungen oder sonstige Bedingungen des Auftraggebers gelten nicht, selbst wenn der Auftragnehmer diesen Bedingungen nicht ausdrücklich widersprochen hat.
        </p>
        <h3>1.2. Erweiterter Anwendungsbereich</h3>
        <p>
          Diese AGB gelten sinngemäß auch für Werbeaufträge im Online- Bereich, im Bereich digitaler, mobiler und zukünftig technisch möglicher weiterer Verwertungs-, Verbreitungswege bzw. Endgeräte wie beispielsweise sämtliche Internet-Portale und dazugehörige Domains (z. B. www.kleinezeitung.at), Applikationen, Services, Widgets und Gadgets, RSSFeeds, Newsletter, Social Media etc., auf PCs,
          Desktops, Notebooks, mobilen Plattformen (Handys, Smartphones, Tablets wie z. B. das iPad), Out of Home- Plattformen (z. B. Infoscreens und anderen Screens), in audiovisuellen Mediendiensten, Navigationsgeräten etc., sofern nicht ausdrücklich etwas anderes festgehalten wird. Sofern Anzeigen auch für Drittmedien gebucht werden, gelten die jeweiligen Allgemeinen Geschäftsbedingungen und
          sonstigen Bedingungen für Anzeigenschaltungen in diesen Medien. Für zusätzlich oder gesondert angebotene Dienstleistungen des Auftragnehmers, deren Ausführung der Auftragnehmer Dritten übertragen hat, gelten zusätzlich zu diesen AGB die jeweiligen Allgemeinen Geschäftsbedingungen und sonstigen Bedingungen des Dritten. Die Allgemeinen Geschäftsbedingungen und sonstigen Bedingungen der
          Drittmedien sowie Dritter, an die der Auftragnehmer die Erbringung von Leistungen für den Auftraggeber übertragen hat, sind unter der Webseite des jeweiligen Drittmediums bzw. Dritten einseh- und ausdruckbar oder werden dem Auftraggeber sonst rechtzeitig zur Kenntnis gebracht.
        </p>
        <h3>1.3. Unternehmer und Verbraucher</h3>
        <p>Sofern in diesen AGB zwischen Unternehmern und Verbrauchern als Auftraggeber unterschieden wird, gilt gemäß § 1 KSchG einerseits jemand, für den der betreffende Werbeauftrag zum Betrieb seines Unternehmens gehört, als Unternehmer und andererseits jemand, für den dies nicht zutrifft, als Verbraucher.</p>
        <h3>1.4. Änderungen, Nebenabreden</h3>
        <p>
          Der Auftragnehmer behält sich vor, diese AGB jederzeit abzuändern oder zu ergänzen (nachfolgend zusammenfassend kurz „Änderungen“). Maßgeblich ist die zum Zeitpunkt des Vertragsabschlusses gültige Fassung der AGB. Nicht für Verbraucher, sondern ausschließlich für Unternehmer als Auftraggeber gilt: Änderungen gelten auch für bereits laufende Werbeaufträge, soweit dem für Unternehmer als
          Auftraggeber gilt: Änderungen gelten auch für bereits laufende Werbeaufträge, soweit dem nicht zwingende gesetzliche Bestimmungen entgegenstehen. Die jeweils aktuellen AGB sind unter https://tarif.kleinezeitung.at/ agb abrufbar. Nebenabreden zu diesen AGB sind nur gültig, wenn sie vom Auftragnehmer schriftlich (möglich auch per E-Mail) bestätigt worden sind. Nebenabreden, mit denen die
          Geltung der AGB zur Gänze ausgeschlossen wird, sind nur gültig, wenn sie in Schriftform vorliegen und von den vertretungsbefugten Organen beider Vertragsteile unterschrieben sind.
        </p>
      </div>
      <div class="col-lg-12">
        <h2>2. ALLGEMEINE BEDINGUNGEN FÜRWERBEAUFTRÄGE</h2>
        <h3>2.1. Platzierung, Konkurrenzausschluss, Auflagenzahl</h3>
        <p>
          Für die Aufnahme von Anzeigen in bestimmten Nummern, bestimmten Ausgaben oder an bestimmten Plätzen der Druckschrift wird keine Gewähr geleistet. Auch bei Entrichtung eines speziellen Platzierungszuschlags (z. B. Titelseite) bleibt es daher dem Auftragnehmer unbenommen, das Inserat zu verschieben (z. B. wenn die Redaktion der Zeitung diesen Platz an diesem Tag benötigt bzw. überhaupt
          eine Seite für Inserate nicht freigibt). Ebenso bleibt es auch bei Entrichtung eines speziellen Platzierungszuschlages (z. B. Titel- oder Rückseite) dem Auftragnehmer unbenommen weitere Werbemittel wie Tip On Cards, Werbemäntel, Banderolen etc. auf der Druckschrift anzubringen. Der Ausschluss von Mitbewerbern kann nur für zwei gegenüberliegende Seiten vereinbart werden. Der Auftraggeber
          nimmt ausdrücklich zur Kenntnis, dass die Print-Auflagenzahl schwanken kann und daher die Auflage möglicherweise am Erscheinungstag einer Beilage vom Auftrag abweicht. Der Auftragnehmer ist daher berechtigt, ohne vorherige Rücksprache mit dem Auftraggeber, die Beilage im eigenen Ermessen in weiteren regionalen Ausgaben zur Erreichung der Auflagenzahl zu schalten bzw. auf andere
          Vertriebsmöglichkeiten auszuweichen. Ansprüche des Auftraggebers sind ausgeschlossen.
        </p>
        <h3>2.2. PR-Texte</h3>
        <p>(Text-)Anzeigen, die auf Grund ihrer redaktionellen Gestaltung nicht als Anzeigen erkennbar sind, werden als solche vom Auftragnehmer deutlich kenntlich gemacht.</p>
        <h3>2.3. Druckunterlagen, Probeabzüge, Anzeigenaufgabe, Belegexemplare</h3>
        <p>
          Der Auftragnehmer gewährleistet die drucktechnisch einwandfreie Wiedergabe der Anzeige. Für die Eignung zum Druck beigestellter oder vom Auftraggeber selbst gestalteter Druckunterlagen, Kosten für die Herstellung von Reinzeichnungen bzw. Abweichungen in der Druckqualität, bedingt durch deren Ausgestaltung sowie auch inhaltliche Fehler solcher beigestellter Druckunterlagen, übernimmt der
          Auftragnehmer keine Haftung, ebenso wenig besteht eine diesbezügliche Prüf- und/oder Hinweispflicht. Grobe Abzüge werden nur auf ausdrücklichen Wunsch geliefert. Der Auftraggeber trägt die Verantwortung für die Richtigkeit der zurückgesandten Probeabzüge. Sendet der Auftraggeber den ihm rechtzeitig übermittelten Probeabzug nicht fristgerecht zurück, so gilt die Genehmigung zum Druck als
          erteilt. Anzeigen werden nur 5 zu 5 Millimeter nach der tatsächlichen Abdruckhöhe berechnet. Für die Richtigkeit fernmündlich aufgegebener Anzeigen und undeutlich geschriebener Textvorlagen kann keine Gewähr übernommen werden. Belegexemplare werden nur geschuldet, wenn der Auftraggeber diese schriftlich in einer bestimmten Anzahl und zu einem bestimmten Preis bestellt hat und diese
          Bestellung vom Auftragnehmer bestätigt wurde; ansonsten wird der Auftragnehmer dem Auftraggeber allenfalls produzierte Belegexemplare als reines Drucknebenprodukt auf Wunsch des Auftraggebers lediglich unverbindlich und ohne Rechtsanspruch des Auftraggebers, sowie je nach Verfügbarkeit und Druckqualität, nach Drucklegung zur Verfügung stellen.
        </p>
        <h3>2.4. Haftung Auftraggeber</h3>
        <p>
          Für den Inhalt der Anzeige haftet der Auftraggeber. Der Auftragnehmer hat kein wie immer geartetes Mitspracherecht oder auch keine Prüfpflicht hinsichtlich des Inhalts, Gestaltung (Text, Bild, Grafik etc.) und dergleichen. Der Auftraggeber garantiert und haftet zunächst dafür, dass der Werbeauftrag (z. B. das Inserat) gegen keinerlei rechtliche Vorgaben (z. B. UWG, GleichbehandlungsG,
          GlücksspielG etc.), nicht gegen das Ansehen des Auftragnehmers oder die guten Sitten verstößt, technischen Anforderungen genügt, er alle rechtlichen Bestimmungen einhält (z. B. UWG, Abgabe lt. GlücksspielG, Kennzeichnung als Werbung, Impressumspflicht) und alle notwendigen Rechte inne- bzw. eingeräumt erhalten hat und daher Rechte Dritter (z. B. Persönlichkeitsrechte (Recht auf Wahrung
          der Ehre, Bildnisschutz, Namensrechte etc.), Immaterialgüterrechte wie Urheber-, Markenschutzrechte bei Fotos, Grafiken, Tonträger, Videobänder usw.) nicht verletzt werden. Der Auftraggeber garantiert daher beispielsweise auch bei Anbot gewerblicher Dienstleistungen die gesetzliche Verpflichtung zur Kennzeichnung seines Unternehmens gem. § 63 GewO bzw. § 6 Abs. 1 E-Commerce-Gesetz (ECG)
          im Onlinebereich bzw. sonstige für den mobilen, digitalen etc. Bereich geltende Gesetzesbestimmungen einzuhalten. Sollte der Auftraggeber rechtlichen Bestimmungen wie z. B. der Kennzeichnungspflicht oder Bestimmungen des GleichbehandlungsG nicht nachkommen, behält sich der Auftragnehmer vor, die Annahme des Anzeigenauftrags abzulehnen bzw. bei begründetem Verdacht eines
          Gesetzesverstoßes, angegebene Daten, wie Namen und Anschrift des Auftraggebers auf Anfrage dem Schutzverband gegen unlauteren Wettbewerb sowie den gem. § 14 Abs 1, 2. u. 3. Satz UWG klagebefugten Einrichtungen oder sonstigen Behörden (z B Magistrat, Polizei), Gerichten oder sonstigen Dritten (z. B. gem. § 18 Abs 4 ECG) mitzuteilen. Der Auftragnehmer behält sich vor, Werbemaßnahmen, die
          vom Österreichischen Werberat beanstandet wurden, nicht abzubilden (einschließl. eines sofortigen Stopps einer bereits laufenden Werbekampagne). Der Auftragnehmer kann aus diesem Grund sowohl die Annahme eines Anzeigenauftrags ablehnen als auch von rechtsverbindlich angenommen Aufträgen zurücktreten. Der Auftragnehmer behält sich im Übrigen vor, Anzeigenaufträge ohne Angabe von Gründen
          abzulehnen. Ansprüche des Auftragnehmers sind ausgeschlossen. Der Auftragnehmer und seine Mitarbeiter sind zu einer entsprechenden Prüfung des Werbeauftrags (z. B. des Inserats oder eines dagegen vorgebrachten Veröffentlichungsbegehrens) nicht verpflichtet, jedoch berechtigt, rechtlich notwendige Adaptionen auch ohne vorherige Rücksprache mit dem Auftraggeber vorzunehmen. Jegliche
          Ansprüche des Auftraggebers, welcher Art auch immer, sind in diesem Zusammenhang ausgeschlossen. Bringt es ein Werbeauftrag mit sich, dass personenbezogene Daten anderer Personen (nachfolgend kurz „betroffene Dritte“) verarbeitet werden, die dem Auftraggeber zuzurechnen sind (z. B. Kontaktdaten eines Mitarbeiters des Auftraggebers in einem Stelleninserat, Kontaktdaten der Ansprechperson
          bei einem Kunden des Auftraggebers, Daten des Glückwunschempfängers in einem Glückwunschinserat etc.), so ist der Auftraggeber als Verantwortlicher gemäß Art. 4 Ziffer 7 der Datenschutz-Grundverordnung (DSGVO) zudem auch für die Einhaltung sämtlicher datenschutzrechtlicher Vorschriften (insbes. der DSGVO und des österreichischen Datenschutzgesetzes, DSG) in Zusammenhang mit der
          Verarbeitung der personenbezogenen Daten des Dritten verantwortlich. Diese Verantwortung besteht für sämtliche Verarbeitungstätigkeiten des Auftraggebers einschließlich der Übermittlung der Daten an den Auftragnehmer zum Zweck der Durchführung des Werbeauftrags (z. B. Veröffentlichung des Inserats). Der Auftraggeber garantiert und haftet in seinem Verantwortungsbereich für die Einhaltung
          der Grundsätze für die Verarbeitung personenbezogener Daten (Art. 5 DSGVO), die Rechtmäßigkeit der Datenverarbeitung (Art. 6 bis Art. 10 DSGVO) wie auch für die Wahrung der Rechte der betroffenen Personen (Art. 12 bis Art. 23 DSGVO). Der Auftraggeber hat den betroffenen Dritten daher insbesondere die Datenschutzinformationen hinsichtlich der Verarbeitung deren Daten durch ihn und
          Übermittlung an den Auftragnehmer zu erteilen, die Rechtmäßigkeit der Datenverarbeitung durch ihn/Übermittlung an den Auftragnehmer sicherzustellen und allfällige Betroffenenbegehren bezüglich der Datenverarbeitung durch ihn/Übermittlung an den Auftragnehmer zu beantworten. Umfasst der Werbeauftrag ein Gewinnspiel, das vom Auftraggeber veranstaltet wird und für das der Auftragnehmer
          lediglich die Plattform zur Verfügung stellt und die technischen Voraussetzungen für die Teilnahme besorgt, so hat der Auftraggeber zum einen als Veranstalter sämtliche rechtlichen Erklärungen in Zusammenhang mit der Auslobung, Teilnahme und Abwicklung des Gewinnspiels selbst abzugeben bzw. von den Teilnehmern einzuholen, und zum anderen als Verantwortlicher nach der DSGVO, selbst für
          die Einhaltung der datenschutzrechtlichen Vorschriften zu sorgen. Der Auftraggeber hat daher notwendige Datenschutzinformationen zu erteilen, Betroffenenbegehren zu beantworten wie auch für die von ihm beabsichtigte Verwendung der Teilnehmerdaten gegebenenfalls erforderliche Einwilligungen der Teilnehmer, in eigener Verantwortung selbst einzuholen. Werden dafür entsprechende Texte (z. B.
          Teilnahmebedingungen, Einwilligungserklärungen) auf der Plattform des Auftragnehmers verwendet, so handelt es sich hierbei um einen vom Auftraggeber rechtlich zu prüfenden Entwurf bzw. geschieht dies letztlich ausschließlich gemäß dem Auftrag und über Weisung des Auftraggebers. Der Auftragnehmer haftet - im Rahmen der Bestimmungen dieser AGB zu Gewährleistung und Leistungsstörungen -
          allein für die Bereitstellung und Verfügbarkeit der technischen Voraussetzungen für die Teilnahme. Nicht für Verbrauchern, sondern ausschließlich für Unternehmer als Auftraggeber gilt: Die Haftung des Auftragnehmers für die Rechtmäßigkeit des Gewinnspiels und die rechtmäßige Verwendung der Daten von Teilnehmern solcher Gewinnspiele durch den Auftraggeber ist, soweit gesetzlich zulässig,
          ausgeschlossen. Der Auftraggeber verpflichtet sich bei Verletzungen von Verpflichtungen aus seinem Verantwortungsbereich, den Auftragnehmer sowie dessen Mitarbeiter hinsichtlich aller Ansprüche, die auf den ausgeführten Anzeigenauftrag (z. B. das erschienene Inserat) gegründet werden (so z. B. auch, wenn sie von Mitbewerbern des Auftragnehmers geltend gemacht werden, sowie
          Einschaltkosten von gerichtlich angeordneten Gegendarstellungen) oder die im Zusammenhang mit der Ausführung des Anzeigenauftrags stehen (z. B. Ansprüche von betroffenen Dritten hinsichtlich der Verarbeitung ihrer Daten) vollkommen schad- und klaglos zu halten, einschließlich Rechtsanwalts- und Verfahrenskosten (vor Behörden, Gerichten), sowie für dem Auftraggeber selbst entstandene
          Nachteile, (z. B eigene Rechtsanwalts- und Verfahrenskosten) zur Gänze selbst aufzukommen. Jegliche nachteiligen Rechtsfolgen, die dem Auftragnehmer aus dem Anzeigenauftrag entstehen, sind daher jedenfalls im Regressweg vom Auftraggeber zu tragen. Nicht für Verbraucher, sondern ausschließlich für Unternehmer als Auftraggeber gilt: Der Auftraggeber verzichtet im Falle der Inanspruchnahme
          von Dritten auf einen Einwand des Mitverschuldens und auf jegliche sonstige Haftung des Auftragnehmers; Ansprüche des Auftraggebers welcher Art auch immer sind soweit gesetzlich zulässig ausgeschlossen.
        </p>
        <h3>2.5. Reklamationen</h3>
        <p>
          Beanstandungen aller Art sind schriftlich (möglich auch per E-Mail) an den Auftragnehmer zu richten. Nicht für Verbraucher, sondern ausschließlich für Unternehmer als Auftraggeber gilt: Beanstandungen sind innerhalb von acht Tagen (Rügepflicht) nach Erscheinen der Anzeige oder Erfüllung eines anderweitigen Werbeauftrags bei sonstigem Verlust der Gewährleistungs- und
          Schadenersatzansprüche zu erheben.
        </p>
        <h3>2.6. Kennziffernverkehr</h3>
        <p>Im Kennziffernverkehr haftet der Auftraggeber für die Rücksendung der den Angeboten beigegebenen Anlagen. Er hat keinen Anspruch auf Auslieferung solcher Einsendungen, die unter missbräuchlicher Inanspruchnahme des Kennzifferndienstes angenommen werden.</p>
        <h3>2.7. Annahme, Ablehnung und Rücktritt von Aufträgen</h3>
        <p>
          Werbeaufträge, gleichgültig von wem diese entgegengenommen wurden, verpflichten den Auftragnehmer erst, wenn sie vom Auftragnehmer angenommen wurden. Auf Wunsch wird die Annahme eines Auftrags vom Auftragnehmer schriftlich (möglich auch per E-Mail) bestätigt. Mündliche Aufträge, deren Annahme nicht schriftlich (möglich auch per E-Mail) bestätigt wurde, binden den Auftragnehmer nicht,
          solange er nicht mit der Auftragsausführung begonnen hat. Die Annahme eines Auftrags wird nur nach einheitlichen Grundsätzen wegen des Inhalts (z. B. Verstoß gegen rechtliche Vorgaben, siehe oben unter Punkt 2.4.), der Herkunft oder der technischen Form abgelehnt. Der Auftragnehmer behält sich vor, Werbemaßnahmen, die vom Österreichischen Werberat beanstandet wurden, nicht abzubilden
          (einschließlich des sofortigen Stopps einer bereits laufenden Werbekampagne). Die Ablehnung bzw. der Stopp werden dem Auftraggeber unverzüglich mitgeteilt. Ferner ist der Auftragnehmer bei Vorliegen eines wichtigen Grundes berechtigt, einen Auftrag abzulehnen, von einem angenommenen Auftrag zurückzutreten oder einen solchen Auftrag außerordentlich zu beenden. Wichtige Gründe sind
          insbesondere grober Verstoß gegen Vertragspflichten durch den Auftraggeber oder Schließung des Unternehmens bzw. Einstellung der Druckschrift.
        </p>
        <h3>2.8. Daueraufträge</h3>
        <p>
          Unbeschadet allfälliger abweichender Vereinbarungen, die in Schriftform vorliegen und von den vertretungsbefugten Organen beider Vertragsteile unterschrieben sein müssen, gelten Verträge, einschließlich Verträge mit Werbeagenturen, über die fortlaufende Erbringung von Leistungen des Auftragnehmers im Sinne dieser AGB (nachfolgend kurz „Daueraufträge“) längstens für die Dauer eines Jahres
          ab Vertragsabschluss (Datum der Auftragsbestätigung, wenn nicht anders festgelegt). Der Auftragnehmer ist unter wichtigen Umständen berechtigt, auch während der Laufzeit eines Dauerauftrages, die Erbringung weiterer Leistungen ohne Rücksicht auf ein ursprünglich vereinbartes Zahlungsziel von der Vorauszahlung des ihm für die Leistung gebührenden Betrages und/oder von dem Ausgleich
          offenstehender Rechnungsbeträge abhängig zu machen, ohne dass hieraus dem Auftraggeber irgendwelche Ansprüche gegen den Auftragnehmer erwachsen.
        </p>
        <h3>2.9. Aufbewahrung</h3>
        <p>Die Pflicht zur Aufbewahrung von Druckunterlagen endet drei Monate nach dem Erscheinen der letzten Anzeige.</p>
        <h3>2.10. Gewährleistung und Leistungsstörung</h3>
        <p>
          Für (Druck-)Fehler, bzw. Fehler, die den Sinn des Inserats nicht wesentlich beeinträchtigen, wird kein Ersatz geleistet. Fehlerhaft gedruckte Kontrollangaben ergeben keinen Anspruch für den Auftraggeber. Der Auftragnehmer lehnt jede Haftung für eventuelle Schäden, die durch Nichterfüllung eines Auftrages an einem bestimmten Tag (ausgenommen bei Anzeigen mit ausdrücklich vereinbarter
          Platzierung etc.) bzw. durch Druckfehler usw. entstehen, ab. Der Auftragnehmer haftet für Schäden dem Auftraggeber gegenüber nur soweit diese rechtswidrig und nachweislich durch den Auftragnehmer, sein Personal oder Erfüllungs- bzw. Besorgungsgehilfen vorsätzlich oder grob fahrlässig herbeigeführt wurden. Für Besorgungsgehilfen wird nur unter den Voraussetzungen des § 1315 ABGB gehaftet.
          Die Haftung für leichte Fahrlässigkeit wird ausgeschlossen, sofern es sich nicht um Personenschäden oder vertragliche Hauptpflichten zur Durchführung des Vertrags (Verschaffung der vereinbarten Leistungen) handelt. Für Schäden Dritter wird nicht gehaftet. Nicht für Verbraucher, sondern ausschließlich für Unternehmer als Kunden gilt: Die Haftung des Auftragnehmers für leichte
          Fahrlässigkeit sowie für Folgeschäden und entgangenen Gewinn wird ausgeschlossen; die Haftung des Auftragnehmers ist – soweit gesetzlich zulässig - der Höhe nach mit dem Betrag des Preises für den betreffenden Auftrag begrenzt. Der Auftragnehmer haftet nicht für beschädigte oder verlorengegangene Daten oder Dateien; gegenüber Verbrauchern als Auftraggeber gilt dies nur insofern als der
          Auftragnehmer zumutbare Abwehrmaßnahmen getroffen hat. Fälle höherer Gewalt (Verkehrsstörungen bei der Zustellung der Druckschrift, Betriebsstörungen u. a.), sind seitens des Auftragnehmers nicht zu vertreten. Der Auftragnehmer behält den Anspruch auf das volle Entgelt, wenn die zu veröffentlichende Werbung in angemessener Zeit nach Beseitigung der Störung veröffentlicht wird. Für
          Verbraucher gelten die gesetzlichen Gewährleistungsbestimmungen. Nicht für Verbraucher, sondern ausschließlich für Unternehmer gilt: Der Auftragnehmer ist zur zweimaligen Nachbesserung innerhalb angemessener Frist berechtigt. Erst nach zwei erfolglosen Nachbesserungsversuchen oder Ablehnung der Nachbesserung durch den Auftragnehmer ist der Auftraggeber zu weiteren Ansprüchen
          (Preisminderung, Vertragsauflösung) entsprechend den gesetzlichen Bestimmungen berechtigt.
        </p>
        <h3>2.11. Anzeigenpreise, Preise für zusätzliche Leistungen und Zahlungskonditionen</h3>
        <p>
          Es gelten die jeweils unter https://tarif.kleinezeitung.at/ für den jeweiligen Anzeigenbereich ausgewiesenen Tarife und Preise für zusätzlich oder gesondert angebotene Dienstleistungen, sofern nicht im Einzelfall ausdrücklich etwas anderes schriftlich vereinbart wurde. Basis für die Verrechnung im Onlinebereich sind die ausgelieferten Ad-Impressions des Adservers des Auftragnehmers bzw.
          von ihm beauftragter Dritter. Nicht für Verbrauchern, sondern ausschließlich für Unternehmer als Auftraggeber gilt: Bei Änderungen der Anzeigenpreise treten die neuen Preise auch bei laufenden Aufträgen sofort in Kraft, soweit dem nicht zwingende gesetzliche Bestimmungen entgegenstehen. Die Rechnung mit Beleg wird spätestens am fünften Tag des auf die Veröffentlichung folgenden Monats
          erstellt. Die Rechnungen des Auftragnehmers sind, sofern nicht im Einzelfall ausdrücklich etwas anderes schriftlich vereinbart wurde, sofort nach Erhalt ohne Abzug zur Bezahlung fällig. Die Rechnungslegung erfolgt durch Übermittlung einer Rechnung im PDF-Format an die bekanntgegebene E-Mail-Adresse. Nur bei Widerspruch zur elektronischen Übermittlung wird eine Papierrechnung zugesandt.
          Mit der Rechnung wird ein Beleg übermittelt. Kann ein Beleg nicht mehr beschafft werden, so tritt an seine Stelle eine Aufnahmebescheinigung des Auftragnehmers. Dies gilt, sofern nicht ausdrücklich eine andere Vereinbarung getroffen wurde. Der Auftragnehmer behält sich vor, nicht eingehobene Werbeabgaben nachzuverrechnen, wenn die Steuerbehörde eine derartige Abgabe einfordert. Die
          Aufrechnung von Forderungen des Auftraggebers gegen Forderungen des Auftragnehmers sind ausgeschlossen. Im Falle von Verbrauchern als Auftraggeber gilt dies nicht, wenn die Forderung gerichtlich festgestellt oder sonst vom Auftragnehmer anerkannt worden ist. Nicht für Verbraucher, sondern ausschließlich für Unternehmer als Auftraggeber gilt: Das Recht zur Zurückbehaltung von Zahlungen
          durch den Auftraggeber ist ausgeschlossen. Bei verschuldetem Zahlungsverzug des Auftraggebers ist der Auftragnehmer berechtigt, die daraus entstehenden notwendigen und zweckentsprechenden Spesen und Kosten, insbesondere für Mahnung, Inkasso und außergerichtliche Rechtsanwaltskosten, soweit diese in einem angemessenen Verhältnis zur betriebenen Forderung stehen, sowie Verzugszinsen in
          gesetzlicher Höhe zusätzlich zu verrechnen. Laufende und weitere Aufträge des Säumigen können vom Auftragnehmer bis zur vollständigen Bezahlung des fälligen Betrags zurückgestellt werden.
        </p>
        <h3>2.11. SEPA-Lastschriftmandat</h3>
        <p>
          Bei Zahlung mittels Einzugsermächtigung beauftragen Sie die Kleine Zeitung GmbH &amp; Co KG widerruflich, die von Ihnen zu entrichtenden Zahlungen bei Fälligkeit zu Lasten Ihres Kontos mittels wiederkehrender SEPA-Lastschrift einzuziehen. Es ist hiermit auch Ihre kontoführende Bank ermächtigt, die Lastschriften einzulösen. Letztere ist auch berechtigt, Lastschriften zurückzuleiten,
          insbesondere dann, wenn das Konto nicht die erforderliche Deckung aufweist. Teilzahlungen sind nicht zu leisten. Sie haben das Recht, innerhalb von 56 Kalendertagen ab Abbuchungsdatum ohne Angabe von Gründen, eine Rückbuchung auf Ihr Konto zu veranlassen. Den Einzug zum jeweiligen Fälligkeitstermin werden wir Ihnen gemeinsam mit der Rechnung spätestens einen Tag vor Fälligkeit vorab
          ankündigen (Pre-Notification). Bitte sorgen Sie für eine entsprechende Kontodeckung. Nutzer im Ausland müssen sicherstellen, dass der Rechnungsbetrag vollständig in EURO auf dem Konto des Auftragnehmers einlangt. Differenzbeträge werden nachbelastet. Bei Fragen zum SEPA-Lastschriftverfahren wenden Sie sich an Ihr Kreditinstitut.
        </p>
        <h3>2.13. Maße</h3>
        <p>Anzeigen, die höher als 220 mm sind, müssen aus umbruchstechnischen Gründen mit gesamter Blatthöhe (Satzspiegel 275 mm) in Rechnung gestellt werden.</p>
        <h3>2.14. Fremdinserate</h3>
        <p>
          Inserate (auch in Beilagen) dürfen lediglich Eigenwerbezwecken dienen. Eine Weitergabe an Dritte, sohin Fremdinserate, bedarf der ausdrücklichen vorherigen schriftlichen Zustimmung des Auftragnehmers. Bei Zuwiderhandeln hat der Auftraggeber als verschuldensunabhängige Pönale den zweifachen Tarifwert der Buchung bei jedem Verstoß, unverzüglich zu bezahlen. Weitergehende Ansprüche des
          Auftragnehmers (z. B. Schadenersatz) bleiben davon unberührt.
        </p>
        <h3>2.15. Verfall von Nachlässen, Insolvenz</h3>
        <p>Der Anspruch auf rückwirkenden Nachlass erlischt, wenn er nicht innerhalb eines Monats nach Ablauf der Jahresfrist für Daueraufträge (siehe oben unter Punkt 2.8.) oder innerhalb eines Monats nach Ablauf der individuell vereinbarten Vertragsdauer geltend gemacht worden ist. Bei Konkurs und Zwangsausgleich entfällt jeglicher Nachlass.</p>
        <h3>2.16. Agenturprovision</h3>
        <p>Leistungen, die eine 15-prozentige Agentur- (Mittler-) Provision rechtfertigen, sind die Mittlerleistung selbst, die Übermittlung einer druckfertigen Unterlage bzw. elektronische Übermittlung des fertigen Sujets, die Übernahme des Delkredere und die Haftung für Copyright-Fragen.</p>
        <h3>2.17. Rücktrittsrecht für Verbraucher und Rücktrittsfolgen</h3>
        <p>
          <strong>Rücktrittsrecht:</strong> Ein Auftraggeber, der Verbraucher im Sinne des Konsumentenschutzgesetzes ist, kann binnen 14 Kalendertagen ab dem Tag des Vertragsabschlusses zurücktreten. Wurde mit der Erbringung der Dienstleistung/Inseratschaltung sofort, jedenfalls innerhalb der Rücktrittsfrist auf ausdrückliches Verlangen des Auftraggebers und bei dessen Kenntnis des Verlustes des
          Rücktrittsrechts bei vollständiger Vertragserfüllung begonnen, so besteht kein Rücktrittsrecht, wenn der Vertrag bereits vollständig erfüllt wurde. Die Erklärung des Rücktritts ist an keine bestimmte Form gebunden. Sie kann z. B. per Brief oder per E-Mail erfolgen. Der Auftraggeber kann dafür auch das Widerrufsformular verwenden, das unter www.kleinezeitung. at/ruecktrittsbelehrung
          heruntergeladen werden kann. Die fristgerechte Absendung der Rücktrittserklärung an den Auftragnehmer, ohne Angabe von Gründen, genügt. Kontaktdaten für die Ausübung des Rücktrittsrechts: Per Post an: Anzeigen und Marketing Kleine Zeitung GmbH &amp; Co KG Gadollaplatz 1, 8010 Graz, per Telefon an: 0316/875-3303, per E-Mail an: meinewerbung@ kleinezeitung.at.
        </p>
        <p>
          <strong>Rücktrittsfolgen:</strong> Wenn der Auftraggeber vom Vertrag zurücktritt, wird der Auftragnehmer sämtliche geleisteten Zahlungen unter Verwendung desselben Zahlungsmittels, dessen sich der Auftraggeber beim Vertragsabschluss über die Dienstleistungen bedient hat, unverzüglich, spätestens jedoch binnen 14 Tagen ab Zugang der Rücktrittserklärung, erstatten. Keinesfalls wird für die
          Rückzahlung ein Entgelt verrechnet. Hat der Auftraggeber verlangt, dass die Dienstleistung während der Rücktrittsfrist beginnen soll und wurde die Dienstleistung vom Auftragnehmer noch nicht vollständig erbracht, so hat der Auftraggeber dem Auftragnehmer einen angemessenen Betrag zu zahlen, der dem Anteil der bis zu dem Zeitpunkt der Rücktrittserklärung bereits erbrachten
          Dienstleistungen im Vergleich zum Gesamtumfang der im Vertrag vorgesehenen Dienstleistungen entspricht. Ausschluss des Rücktrittsrechts: Bei Vertragsabschlüssen in Geschäftsräumen/am Messestand (sofern dort gewöhnlich der Verkauf stattfindet); außerhalb von Geschäftsräumen, wenn der Betrag EURO 50,- nicht übersteigt (§ 1 FAGG), Straßenverkauf (Einzelverkauf/Bargeschäft), wenn das Entgelt
          EURO 25,- nicht übersteigt oder wenn das Geschäft vom Verbraucher selbst angebahnt (§ 3 KSchG) wurde.
        </p>
        <h3>2.18. Stornierungen</h3>
        <p>
          Bei Stornierungen gebührt dem Auftragnehmer eine Stornogebühr. Bei Stornos (jeweils vor Anzeigenschluss): bis eine Woche kostenlos, ab einer Woche 30 %, ab einem Tag 50 %. Bei Beilagen (Beilagenpreis) und Sonderwerbeformen (Verarbeitungspreis): Bis einen Monat kostenlos, ab einem Monat bis neun Tage 20 %, ab acht Tage 50 % des Beilagen- bzw. Verarbeitungspreises. Die
          Beilagenstornierungskosten werden auf Basis des Beilagenpreises der niedrigsten Gewichtsklasse berechnet. Eine Stornierung von Folgeaufträgen (ein Auftrag über mehrere Schaltungen) ist nach der ersten Inseratschaltung nicht mehr möglich. Die Stornogebühr stellt einen pauschalierten Schadenersatz dar.
        </p>
        <h3>2.19. Immaterialgüterrechte</h3>
        <p>
          Das Eigentum und die Rechte an Idee, Konzeption, Gestaltung, Layout, Titel, Text, Fotos etc. an vom Auftragnehmer gestalteten Sujets verbleiben beim Auftragnehmer, sofern mit dem Auftraggeber im Einzelfall nicht ausdrücklich schriftlich etwas anderes vereinbart wird. Dieses Anzeigensujet darf daher lediglich in der Kleinen Zeitung, auf www.kleinezeitung.at oder ausdrücklich in vom
          Auftragnehmer gestatteten Medien in unveränderter Weise veröffentlicht werden. Jegliche auch nur teilweise Bearbeitung, Vervielfältigung und/oder anderweitige Veröffentlichung, Verbreitung und/oder Verwertung des Anzeigensujets bedarf der ausdrücklichen Zustimmung des Auftragnehmers.
        </p>
        <h3>2.20. Geheimhaltung</h3>
        <p>
          Der Auftraggeber verpflichtet sich, sämtliche ihm zur Verfügung gestellten Informationen (z. B. Passwort, Benutzernamen, Mediadaten u. a.) absolut vertraulich zu behandeln, sicher aufzubewahren und vor unbefugten Zugriffen Dritter zu schützen. Der Auftraggeber ist verpflichtet, eine allfällige unbefugte oder missbräuchliche Verwendung umgehend dem Auftragnehmer zu melden. Der
          Auftraggeber verpflichtet sich, die Informationen nur an Personen weiterzugeben, die sich ihrerseits zu umfassender Geheimhaltung gegenüber dem Auftraggeber verpflichtet haben. Diese Verpflichtung zur Geheimhaltung besteht auch nach Beendigung des Vertragsverhältnisses zwischen dem Auftragnehmer und dem Auftraggeber weiter fort. Für aus der Verletzung dieser Geheimhaltungspflicht
          resultierende Schäden hält der Auftraggeber den Auftragnehmer vollkommen schad- und klaglos (inkl. Rechtsanwalts- und Verfahrenskosten).
        </p>
        <h3>2.21. Anzuwendendes Recht und Gerichtsstand</h3>
        <p>
          Erfüllungsort ist Graz. Es gilt österreichisches materielles Recht unter Ausschluss der Verweisungsnormen des internationalen Privatrechts. Bei Verbrauchern gilt diese Rechtswahl nur insoweit, als zwingende Bestimmungen des Rechts des Staates der Europäischen Union, in dem der Verbraucher seinen gewöhnlichen Aufenthalt hat, nicht verdrängt werden. Nicht für Verbraucher, sondern
          ausschließlich für Unternehmer als Auftraggeber gilt: Für alle aus und im Zusammenhang mit dem Vertrag einschließlich dieser AGB entstehenden Streitigkeiten, wie auch des Zustandekommens und der Beendigung des Vertrages, wird die ausschließliche Zuständigkeit des sachlich zuständigen Gerichts in Graz vereinbart.
        </p>
        <h3>2.22. Salvatorische Klausel</h3>
        <p>
          Nicht für Verbraucher, sondern ausschließlich für Unternehmer als Auftraggeber gilt: Sollten einzelne Bestimmungen des Vertrages, einschließlich dieser AGB (einschließlich dieser Regelung) ganz oder teilweise unwirksam sein oder werden, bleibt die Wirksamkeit der übrigen Bestimmungen oder der übrigen Teile solcher Bestimmungen unberührt. An die Stelle der unwirksamen Bestimmung oder der
          unwirksamen Teile tritt eine wirksame Bestimmung, die der ursprünglichen Bestimmung am nächsten kommt. Dies gilt auch für etwaige unvorhergesehene Lücken des Vertrages, einschließlich dieser AGB.
        </p>
      </div>
      <div class="col-lg-12">
        <h2>BESONDERE BESTIMMUNGEN FÜR ONLINE-WERBUNG</h2>
        <p>
          Zusätzlich zu den vorstehenden allgemeinen Bedingungen für Werbeaufträge gelten nachfolgende Bestimmungen für Anzeigenschaltungen und andere Werbeformen im Online-Bereich, im Bereich digitaler, mobiler und zukünftig technisch möglicher weiterer Verwertungs-, Verbreitungswege bzw. Endgerätewie beispielsweise sämtliche Internet-Portale und dazugehörige Domains (z. B. www.
          kleinezeitung.at), Applikationen, Services, Widgets und Gadgets, RSS-Feeds, Newsletter, Social Media etc., auf PC, Desktops, Notebooks, mobilen Plattformen (Handys, Smartphones, Tablets wie z. B. das iPad), Out of Home-Plattformen (z. B. Infoscreens und anderen Screens), in audiovisuellen Mediendiensten, Navigationsgeräten etc. (zusammenfassend kurz:
          <strong>„Online-Werbung“</strong>).Die Bestimmungen für Online Werbung gehen den allgemeinen Bedingungen für Werbeaufträge im Fall von Widersprüchen im Zweifel zur Gänze vor. Sämtliche Informationen, Dokumente, Unterlagen, Dateien, welche für die Schaltung der jeweiligen Online-Werbung erforderlich sind (z. B. Grafiken, Rich Media Banner, Texte, Videos, Links und anderes, zusammenfassend
          kurz <srong>„Werbematerial“</srong>), müssen spätestens drei Werktage bei Standardwerbeformen (lt. IAB) bzw. fünf Werktage bei anderen Werbeformen vor der festgelegten Ersteinbindung auf der Website vollständig, fehlerfrei und entsprechend seitens des Auftraggebers übermittelt werden. Der Auftragnehmer hat das Recht, das übermittelte Werbematerial auf seine Darstellungstauglichkeit und
          technische Eignung (insb. passendes Format, Darstellungstechnologie und Dateigrößen etc.) zu prüfen und gegebenenfalls zur Anpassung an den Auftraggeber zu retournieren. Dabei ist der Auftragnehmer auch berechtigt, diese Anpassungen nach vorheriger Vereinbarung mit dem Auftraggeber selbst vorzunehmen und diesem die Kosten dafür zu verrechnen. Bei Nichterfüllung der technischen
          Voraussetzungen zur Schaltung der Online-Werbung, bzw. zur Ermittlung der technischen Werbeinformationen (z. B. Anzahl der Ad-Impressions) ist der Auftragnehmer von allen daraus, sowie aus den durch die externe (Ad-)Server-Anbindung der veröffentlichten Online-Werbung resultierenden Ansprüchen freigestellt, wobei sämtliche Kosten umfasst sind. Wenn eine fehlerfreie Auftragsabwicklung
          nicht gewährleistet werden kann, ist der Auftragnehmer unabhängig von einem eventuellen Schaden berechtigt, das Werbematerial unverzüglich aus der Schaltung zu nehmen und ist von jeglicher Haftung im Zusammenhang mit einer derartigen Maßnahme befreit. Der Auftraggeber verpflichtet sich, dem Auftragnehmer sämtliche Daten richtig und vollständig anzugeben, die zur Identifizierung des
          Auftraggebers im Sinne des § 6 Abs 1 E-Commerce Gesetz (ECG) notwendig sind. Die Übergabe der Daten hat im elektronischen Weg mittels E-Mail-Anhangs zu erfolgen. Diese Daten müssen den nach E-Commerce-Gesetz (ECG) oder sonstigen in Betracht kommenden gesetzlichen Anforderungen zur Kennzeichnung kommerzieller Kommunikation genügen, sowie gegebenenfalls den einschlägigen, jeweils aktuell
          gültigen Vorgaben durch das Fern- und Auswärtsgeschäfte-Gesetz, das Telekommunikationsgesetz 2021 (TKG) sowie durch das Mediengesetz und alle sonstigen für das Schalten der Online Werbung geltenden gesetzlichen Vorschriften entsprechen. Insbesondere müssen die übermittelten Daten eine rechtskonforme Kennzeichnung von Auftraggebern kommerzieller Kommunikation zulassen. Der Auftragnehmer
          gibt keine Garantie über die Platzierung, Reihenfolge, Aufteilung der Ad-Impressionswährend der Werbekampagne. Rechtliche Verantwortung: Der Auftragnehmer ist dazu berechtigt, die gesamte Online-Werbung oder Teile davon aus redaktionellen, rechtlichen, technischen oder sonstigen Gründen (z. B. Verstoß gegen die guten Sitten oder das Ansehen des Auftragnehmers), zurückzuweisen oder
          nachträglich unverzüglich zu sperren, wobei eine vorherige Absprache mit dem Auftraggeber nicht notwendig ist, dieser aber von der Maßnahme ehestmöglich informiert wird. Die Sperrung befreit den Auftraggeber nicht von der Zahlung der vertraglich vereinbarten Vergütung. Der Auftraggeber hat vielmehr die Möglichkeit, das Werbematerial innerhalb einer Nachfrist von zwei Wochen ab
          Information durch den Auftragnehmer nachzubessern. Wird innerhalb dieses Zeitraums seitens des Auftraggebers ein rechtskonformer Zustand hergestellt, wird die Online-Werbung wieder geschalten. Der Auftraggeber hat dem Auftragnehmer die Rechtskonformität des nachgebesserten Werbematerials schriftlich zu bestätigen. Weitergehende Erstattungs- oder Schadenersatzansprüche des Auftraggebers
          aus einer solchen Sperrung sind ausgeschlossen. Für Online-Werbung, die von der Content Performance Group GmbH, FN 345338 a, mit Sitz in Wien vermarktet wird, gelten die Allgemeinen Geschäftsbedingungen und Spezifikationen der Content Performance Group GmbH.
        </p>
        <p>
          Informationen zum Datenschutz finden Sie unter:
          <a
            href="https://www.kleinezeitung.
at/WerbemarktDSI"
            target="_blank"
            class="content-link"
            >https://www.kleinezeitung. at/WerbemarktDSI.</a
          >
          Die Allgemeinen Geschäfts-bedingungen und Spezifikationen von Content Performance Group GmbH finden Sie auch auf: <a href="https://www.copegroup.com/allgemeine-geschaeftsbedingungen/" target="_blank" class="content-link">https://www.copegroup.com/allgemeine-geschaeftsbedingungen/</a>,
          <a href="https://www.copegroup.com/buchungsbedingungen/" target="_blank" class="content-link">https://www.copegroup.com/buchungsbedingungen/</a>,
          <a
            href="https://www.copegroup.com/wp-content/uploads/2021/11/Spezifikationen_
COPE-Netzwerk-sd-one-2021-11-11.pdf"
            target="_blank"
            class="content-link"
            >https://www.copegroup.com/wp-content/uploads/2021/11/Spezifikationen_ COPE-Netzwerk-sd-one-2021-11-11.pdf</a
          >
        </p>
        <p>Satz- und Druckfehler vorbehalten.</p>
        <p>
          Kleine Zeitung GmbH &amp; Co KG<br />
          Gadollaplatz 1<br />
          A-8010 Graz<br />
          Telefon: <a href="tel:+433168753781" class="content-link">0316-875-3781</a><br />
          Email: <a href="mailto:meinewerbung@kleinezeitung.at" class="content-link">meinewerbung@kleinezeitung.at</a><br />
          Firmenbuchnummer: FN 185959 w<br />
          Firmenbuchgericht: Landesgericht für Zivilrechtssachen Graz<br />
          UID-Nr.: ATU47346803<br />
        </p>
        <p>
          Komplementärin:<br />
          Kleine Zeitung GmbH<br />
          Gadollaplatz 1<br />
          A-8010 Graz<br />
          Firmenbuchnummer: FN 183862 k<br />
          Firmenbuchgericht: Landesgericht für Zivilrechtssachen Graz
        </p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  components: {},
});
</script>
